body {
    background-color: $dark;
    background-image: url("/images/zenbg1.jpg"), url("/images/zenbg2.jpg");
    background-repeat: repeat-x, repeat;
    background-position: top center;
    font-weight: 300;
    font-size: 15px;
    color: white;
    margin: 0;

    main {
        min-height: calc( 100vh - 197.5px - 48px - 24px ) !important;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        min-height: calc( 100vh - 593px );
    }

    .lead {
        line-height: 1.8rem;
    }

    .navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
        flex-direction: column;
    }

    .navbar-toggler {
        border: none;

        &:active, &:focus, &:focus-visible {
            outline: none;
            box-shadow: none;
        }
    }

    main a, main a:hover {
        color: $primary;
        text-decoration: none;
        border-bottom: 0.05rem dotted $primary;
        text-shadow: 0 0 4px $dark;
    }

    a.btn {
        text-shadow: none;
    }

	code {
        font-size: 1.4em;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: 2.4em;
        color: $dark;
        background: $light;
        padding: 1.2em 0.8em 0.4em;
	}

    strong {
        font-weight: 700;
    }

    svg#external-link {
        margin-top: -0.35rem;
    }

    footer a#author {
        color: $light;
        text-decoration: none;
        border-bottom: 0.05rem dotted $light;
    }

    #passgrinder {
        max-width: 460px;
        height: auto;
        margin: 0 auto;

        form {
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }

        small {
            line-height: 1em;
            font-style: italic;
            font-size: 0.8rem;
            font-weight: 300;
            max-width: 412px;
            margin: 0 auto;
        }

        .toggle-password, 
        .toggle-copy {
            cursor: pointer;
            max-width: 44px;
        }

        #pg-message {
            z-index: 1080;
            #success, 
            #fail, 
            #reset {
                font-style: italic;
                width: 100%;
                text-align: center;
            }
        }

        input[type=text], 
        input[type=password] {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
        }

        input[type="submit"], 
        input[type="reset"] {
            width: 100%;
            max-width: 200px;
            margin: 0 auto;
            color: $dark !important;
        }

        input[type="submit"] {
            background: $primary !important;
            border-color: $primary !important;
        }

        input[type="reset"] {
            background: $light !important;
            border-color: $light !important;
        }

        .text-muted {
            color: $secondary !important;
        }

        .form-text {
            display: block;
        }

        .form-check {
            padding-left: 0;
            margin-bottom: 0;
            min-height: auto;
        }

        .form-check-inline {
            margin-right: 0.8rem;
        }

        .form-check-inline:last-of-type {
            margin-right: 0;
        }

        [type="radio"]:checked, 
        [type="radio"]:not(:checked) {
            position: absolute;
            left: -9999px;
        }

        [type="radio"]:checked + label, 
        [type="radio"]:not(:checked) + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
        }

        [type="radio"]:checked + label:before, 
        [type="radio"]:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0px;
            width: 18px;
            height: 18px;
            border: 1px solid $light;
            border-radius: 100%;
            background: $light;
        }

        [type="radio"]:checked + label:after, 
        [type="radio"]:not(:checked) + label:after {
            content: '';
            width: 18px;
            height: 18px;
            background: #6eb981;
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        [type="radio"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        [type="radio"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        input[type=text]:focus, 
        input[type=password]:focus {
            box-shadow: none;
        }


    }

}